import { Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { AxiosApiClient, InternationalizationApi, TranslationDto } from "@smallstack/axios-api-client";
import { LocaleService, TranslationStore } from "@smallstack/i18n-components";

@Injectable()
export class AbstractTranslationStore extends TranslationStore {
  constructor(
    private axiosApiClient: AxiosApiClient,
    localeService: LocaleService
  ) {
    super(localeService);
  }

  public override getByKey(key: string): TranslationDto {
    const valueFromStore: TranslationDto = super.getByKey(key);
    if (valueFromStore) return valueFromStore;
  }

  protected override async loadModels(query: ApiQueryRequest): Promise<Page<TranslationDto>> {
    const res = await this.axiosApiClient.get(InternationalizationApi).getTranslations(query);
    return res.data;
  }
  protected override async loadModelById(id: string): Promise<TranslationDto> {
    const res = await this.axiosApiClient.get(InternationalizationApi).getTranslation({ id });
    return res.data;
  }
  protected override async deleteModelById(id: string): Promise<any> {
    const res = await this.axiosApiClient.get(InternationalizationApi).deleteTranslation({ id });
    return res.data;
  }
  protected override async createModel(translation: TranslationDto): Promise<TranslationDto> {
    const res = await this.axiosApiClient.get(InternationalizationApi).createTranslation({ translation });
    return res.data;
  }
  protected override async patchModel(id: string, translation: Partial<TranslationDto>): Promise<TranslationDto> {
    const res = await this.axiosApiClient.get(InternationalizationApi).patchTranslation({ id, translation });
    return res.data;
  }
  protected override async putModel(translation: TranslationDto): Promise<TranslationDto> {
    const res = await this.axiosApiClient
      .get(InternationalizationApi)
      .putTranslation({ id: translation.id, translation });
    return res.data;
  }
}

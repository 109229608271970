import { Injectable } from "@angular/core";
import { LocaleService } from "@smallstack/i18n-components";
import { ResellerApiClient } from "../../api-client/services/api-client.factory";
import { AbstractTranslationStore } from "./abstract-translation.store";

@Injectable({ providedIn: "root" })
export class ResellerTranslationStore extends AbstractTranslationStore {
  constructor(resellerApiClient: ResellerApiClient, localeService: LocaleService) {
    super(resellerApiClient, localeService);
  }
}
